<template>
  <SectionSpacer class="pt-20" :margin="true">
    <PageTitle as="h1" title="Die Band" />
    <BandMembers />
    <TheBandSection :show-more-button="false" />
  </SectionSpacer>
</template>

<script>
import PageTitle from "@/components/Title/PageTitle";
import SectionSpacer from "@/components/Wrapper/SectionSpacer";
import { useMeta } from "vue-meta";
import TheBandSection from "@/sections/TheBandSection";
import BandMembers from "@/components/modules/BandMembers";

export default {
  name: "TheBandView",
  components: { BandMembers, TheBandSection, SectionSpacer, PageTitle },
  setup() {
    useMeta({
      title: "Die Band",
    });
  },
};
</script>

<style scoped></style>
