<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Vitamin D` : `Vitamin D`
    }}</template>
  </metainfo>
  <!-- Header -->
  <AppHeader />
  <!-- Main -->
  <AppMain>
    <!-- Content Slot -->
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </AppMain>
  <!-- Footer -->
  <AppFooter />
</template>

<style>
#app {
  @apply text-gray-800;
}

body,
html {
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-no-repeat bg-center;
}

html::before {
  content: " ";
  display: block;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -10;
}

@media screen and (max-width: 768px) {
  html::before {
    background-image: url("~@/assets/background6.jpg");
  }
}
@media screen and (min-width: 769px) {
  html::before {
    background-image: url("~@/assets/background4.jpg");
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<script>
import AppHeader from "@/partials/AppHeader";
import AppMain from "@/partials/AppMain";
import AppFooter from "@/partials/AppFooter";
export default {
  components: { AppFooter, AppMain, AppHeader },
  methods: {
    klaro() {
      window.klaro.getManager().watch({
        update: function (obj, name) {
          if (name === "applyConsents") {
            window.location.reload();
          }
        },
      });
    },
  },
  mounted() {
    this.klaro();
  },
};
</script>
