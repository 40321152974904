<template>
  <div class="alert-info">{{ text }}</div>
</template>

<script>
export default {
  name: "AlertInfo",
  props: {
    text: String,
  },
};
</script>

<style scoped>
.alert-info {
  @apply text-center;
}
</style>
