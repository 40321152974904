import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import axios from "axios";
import VueAxios from "vue-axios";
import { api } from "@/data/api";
import { createMetaManager } from "vue-meta";

axios.defaults.baseURL = api.url;
axios.defaults.headers.common["Authorization"] = "Bearer " + api.token;

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(createMetaManager())
  .mount("#app");
