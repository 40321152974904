<template>
  <div class="video-wrapper" v-if="videoid">
    <iframe
      class="video"
      :src="url"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: {
    videoid: {
      type: String,
    },
  },
  computed: {
    url() {
      return "https://www.youtube-nocookie.com/embed/" + this.videoid;
    },
  },
};
</script>

<style scoped>
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-wrapper {
  position: relative;
  padding-top: 56.25%;
}
</style>
