<template>
  <div class="loader-wrapper">
    <div class="loader l1"></div>
    <div class="loader l2"></div>
    <div class="loader l3"></div>
  </div>
</template>

<script>
export default {
  name: "TextLoader",
};
</script>

<style scoped>
.loader-wrapper {
  @apply grid grid-cols-1 gap-6;
}
.loader {
  @apply animate-pulse bg-gray-200 bg-opacity-75 h-6 rounded-full mx-auto;
}
.l1 {
  width: 100%;
}

.l2 {
  width: 80%;
}
.l3 {
  width: 90%;
}
</style>
