<template>
  <section>
    <PageTitle as="h2" title="Live" />
    <PageContainer>
      <EventModule :max="4" :show-more="true" />
    </PageContainer>
  </section>
</template>

<script>
import PageTitle from "@/components/Title/PageTitle";
import PageContainer from "@/components/Wrapper/PageContainer";
import EventModule from "@/components/modules/EventModule";
export default {
  name: "EventSection",
  components: { EventModule, PageContainer, PageTitle },
};
</script>

<style scoped></style>
