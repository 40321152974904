import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import EventView from "@/views/EventView";
import DownloadsView from "@/views/DownloadsView";
import PrivacyView from "@/views/PrivacyView";
import ContactView from "@/views/ContactView";
import TheBandView from "@/views/TheBandView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/die-band",
    name: "theband",
    component: TheBandView,
  },
  {
    path: "/live",
    name: "live",
    component: EventView,
  },
  {
    path: "/downloads",
    name: "downloads",
    component: DownloadsView,
  },
  {
    path: "/datenschutz",
    name: "privacy",
    component: PrivacyView,
  },
  {
    path: "/impressum",
    name: "contact",
    component: ContactView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
