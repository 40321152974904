<template>
  <div class="copyright">©{{ year }} <span>Vitamin D</span></div>
</template>

<script>
export default {
  name: "AppCopyright",
  computed: {
    /**
     * Gibt das aktuelle Jahr aus.
     * @returns {number}
     */
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.copyright {
  @apply text-sm text-center pt-6 mt-6 pb-7;
}
.copyright span {
  @apply font-bold;
}
</style>
