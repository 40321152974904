<template>
  <template v-if="text">
    <!-- Text -->
    <div class="text-wrapper" v-html="text.text"></div>
  </template>
  <template v-else>
    <!-- Loader -->
    <TextLoader />
  </template>
</template>

<script>
import TextLoader from "@/components/Loader/TextLoader";
export default {
  name: "TextPlugin",
  components: { TextLoader },
  props: {
    shortcode: String,
  },
  data() {
    return {
      text: null,
    };
  },
  mounted() {
    this.loadText();
  },
  methods: {
    options() {
      return {
        fields: { text: 1 },
        filter: { shortcode: this.$props.shortcode },
      };
    },
    loadText() {
      this.axios.post("/api/collections/get/text", this.options()).then((r) => {
        this.text = r.data.entries[0];
      });
    },
  },
};
</script>

<style scoped>
.text-wrapper:deep(p) {
  @apply mb-4 break-words;
}
.text-wrapper {
  @apply text-center;
}

.text-wrapper:deep(h2) {
  @apply text-xl font-bold;
}
</style>
