<template>
  <SectionSpacer class="pt-20" :margin="true">
    <section>
      <PageContainer>
        <PageTitle as="h1" title="Live" />
        <EventModule :show-more="false" />
      </PageContainer>
    </section>
  </SectionSpacer>
</template>

<script>
import EventModule from "@/components/modules/EventModule";
import PageTitle from "@/components/Title/PageTitle";
import PageContainer from "@/components/Wrapper/PageContainer";
import SectionSpacer from "@/components/Wrapper/SectionSpacer";
import { useMeta } from "vue-meta";
export default {
  name: "EventView",
  components: { SectionSpacer, PageContainer, PageTitle, EventModule },
  setup() {
    useMeta({
      title: "Events",
    });
  },
};
</script>

<style scoped></style>
