<template>
  <template v-if="members">
    <PageContainer>
      <MemberWrapper>
        <BandMember
          v-for="(member, index) in members"
          v-bind:key="'member-view-' + index"
          :member="member"
        />
      </MemberWrapper>
    </PageContainer>
  </template>
  <template v-else>
    <PageContainer>
      <TextLoader />
    </PageContainer>
  </template>
</template>

<script>
import PageContainer from "@/components/Wrapper/PageContainer";
import TextLoader from "@/components/Loader/TextLoader";
import BandMember from "@/components/Items/BandMember";
import MemberWrapper from "@/components/Wrapper/MemberWrapper";
export default {
  name: "BandMembers",
  components: { MemberWrapper, BandMember, TextLoader, PageContainer },
  data() {
    return {
      members: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.axios
        .post("/api/collections/get/band", { populate: 2, sort: { pos: 1 } })
        .then((r) => {
          this.members = r.data.entries;
        });
    },
  },
};
</script>

<style scoped></style>
