/**
 * Header Navigation Links
 * @type {[{route: string, name: string},{route: string, name: string},{route: string, name: string},{route: string, name: string}]}
 */
export const header = [
  {
    route: "home",
    name: "Startseite",
  },
  {
    route: "theband",
    name: "Die Band",
  },
  {
    route: "live",
    name: "Live",
  },
  {
    route: "downloads",
    name: "Downloads",
  },
  {
    route: "contact",
    name: "Kontakt",
  },
];

/**
 * Footer Navigation Links.
 * @type {[{route: string, name: string},{route: string, name: string}]}
 */
export const footer = [
  {
    route: "privacy",
    name: "Datenschutz",
  },
  {
    route: "contact",
    name: "Kontakt & Impressum",
  },
];

import Facebook from "../assets/images/social/facebook.png";
import YouTube from "../assets/images/social/youtube.png";
import Instagram from "../assets/images/social/instagram.png";
import TikTok from "../assets/images/social/tiktok.png";

/**
 * Social Media Links
 * @type {*[]}
 */
export const social = [
  {
    url: "https://www.facebook.com/Vitamin-D-230657828872584/",
    name: "Facebook",
    icon: Facebook,
  },
  {
    url: "https://www.instagram.com/vitamin_dlive/",
    name: "Instagram",
    icon: Instagram,
  },
  {
    url: "https://www.youtube.com/user/Hitschmiede",
    name: "YouTube",
    icon: YouTube,
  },
  {
    url: "https://www.tiktok.com/@vitamindlive",
    name: "TikTok",
    icon: TikTok,
  },
];
