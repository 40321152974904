<template>
  <div class="member-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: "MemberWrapper",
};
</script>

<style scoped>
.member-wrapper {
  @apply grid grid-cols-1 md:grid-cols-3 gap-16;
}
</style>
