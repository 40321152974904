<template>
  <BGWrapper
    class="bg-black text-white border-t-4 border-b-4 border-amber-300 bg-parallax"
    v-bind:class="{ ios: isIos }"
    v-bind:style="{ backgroundImage: 'url(' + Guitar + ')' }"
  >
    <PageContainer>
      <TheBand :show-more-button="showMoreButton" />
    </PageContainer>
  </BGWrapper>
</template>

<script>
import BGWrapper from "@/components/Wrapper/BGWrapper";
import PageContainer from "@/components/Wrapper/PageContainer";
import TheBand from "@/components/modules/TheBand";
import Guitar from "../assets/guitar.jpg";

export default {
  name: "TheBandSection",
  components: { TheBand, PageContainer, BGWrapper },
  props: {
    showMoreButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Guitar: Guitar,
    };
  },
  computed: {
    isIos() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
  },
};
</script>

<style scoped>
.ios {
  background-attachment: scroll !important;
}

.bg-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @apply bg-cover bg-right md:bg-center;
}
</style>
