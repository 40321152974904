<template>
  <SectionSpacer>
    <!-- Start -->
    <HomeStartSection />
    <!-- Auftritte -->
    <EventSection />
    <!-- Die Band -->
    <TheBandSection :show-more-button="true" />
    <!-- Videos -->
    <VideoSection />
    <!-- Fotos -->
    <PhotoSection />
    <!-- Kontakt -->
    <ContactSection />
  </SectionSpacer>
</template>

<script>
import SectionSpacer from "@/components/Wrapper/SectionSpacer";
import { useMeta } from "vue-meta";
import HomeStartSection from "@/sections/HomeStartSection";
import EventSection from "@/sections/EventSection";
import TheBandSection from "@/sections/TheBandSection";
import PhotoSection from "@/sections/PhotoSection";
import ContactSection from "@/sections/ContactSection";
import VideoSection from "@/sections/VideoSection";

export default {
  name: "HomeView",
  components: {
    VideoSection,
    ContactSection,
    PhotoSection,
    TheBandSection,
    EventSection,
    HomeStartSection,
    SectionSpacer,
  },
  setup() {
    useMeta({
      description:
        "Vitamin D live - Die Liveshow für jeden, der auf deutschsprachige Hits steht. Vitamin D ist die Band, die Euch abfeiern oder auch in Erinnerungen schwelgen lässt.",
    });
  },
};
</script>
