<template>
  <CardWrapper>
    <div class="date">{{ date }}</div>
    <div class="title">{{ event.title }}</div>
    <div class="manager" v-if="event.manager">
      <div>
        <span v-if="event.place">{{ event.place }}, </span>{{ event.time_from }}
        <span v-if="event.time_to">bis {{ event.time_to }} Uhr</span>
      </div>
      <div>
        Veranstalter: <span class="font-bold">{{ event.manager }}</span>
      </div>
    </div>
  </CardWrapper>
</template>

<script>
import moment from "moment";
import CardWrapper from "@/components/Wrapper/CardWrapper";

export default {
  name: "EventItem",
  components: { CardWrapper },
  props: {
    event: Object,
  },
  computed: {
    date() {
      let date = moment(this.event.date).format("DD.MM.YYYY");

      if (
        parseInt(moment(this.event.date).format("YYYY")) ===
        new Date().getFullYear()
      ) {
        date = moment(this.event.date).format("DD.MM");
      }
      return date;
    },
  },
};
</script>

<style scoped>
.title {
  @apply font-bold text-2xl;
}
.manager {
  @apply pt-2 mt-2 border-t border-gray-200 border-dashed;
}
.date {
  @apply text-amber-500 font-bold text-xl bg-black inline-block py-1 px-2 -rotate-3 mb-2 rounded-xl;
}
</style>
