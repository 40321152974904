<template>
  <div>
    <router-link :to="{ name: 'home' }">
      <img class="logo" :src="BandLogo" alt="Vitamin D" />
    </router-link>
  </div>
</template>

<script>
import BandLogo from "../assets/logo_white.2123c8a6.png";

export default {
  name: "BandLogo",
  data() {
    return {
      BandLogo: BandLogo,
    };
  },
};
</script>

<style scoped>
.logo {
  @apply h-10 md:h-12 w-auto;
}
</style>
