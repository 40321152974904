<template>
  <div class="navigation-wrapper">
    <HeaderNavigationLink
      v-for="(link, index) in navigation"
      v-bind:key="'header-navigation-link-' + index"
      :link="link"
    />
  </div>
</template>

<script>
import { header } from "@/data/navigation";
import HeaderNavigationLink from "@/components/Items/HeaderNavigationLink";

export default {
  name: "HeaderNavigation",
  components: { HeaderNavigationLink },
  data() {
    return {
      navigation: header,
    };
  },
};
</script>

<style scoped>
.navigation-wrapper {
  @apply flex space-x-7 items-center;
}
</style>
