<template>
  <div class="member">
    <img :src="image" :alt="member.name" class="image" />
    <div class="name">{{ member.type }}: {{ member.name }}</div>
    <div class="nickname">{{ member.nickname }}</div>
  </div>
</template>

<script>
import { api } from "@/data/api";

export default {
  name: "BandMember",
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return api.thumbs + this.member.photo.path;
    },
  },
};
</script>

<style scoped>
.member {
  @apply flex flex-col justify-center items-center;
}
.image {
  @apply h-60 w-60 rounded-full bg-gray-200 mx-auto bg-no-repeat bg-center bg-cover object-cover;
}
.name {
  @apply text-amber-500 font-bold text-xl bg-black inline-block py-1 px-2 -rotate-3 mb-2 -mt-8 rounded-xl;
}
.nickname {
  @apply text-gray-700 font-bold;
}
</style>
