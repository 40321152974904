<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CardWrapper",
};
</script>

<style scoped>
.card {
  @apply bg-white bg-opacity-60 p-4 shadow-sm rounded-2xl shadow-sm h-full;
}
</style>
