<template>
  <a :href="link.url" target="_blank" :title="link.name">
    <img class="icon" :src="link.icon" :alt="link.name" />
  </a>
</template>

<script>
export default {
  name: "SocialIcon",
  props: {
    link: Object,
  },
};
</script>

<style scoped>
.icon {
  @apply h-8 w-8 md:w-12 md:h-12 rounded-full hover:scale-125 transition-all duration-100 ease-linear hover:rotate-6 hover:shadow-md;
}
</style>
