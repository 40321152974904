<template>
  <div class="item-spacer">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ItemSpacer",
};
</script>

<style scoped>
.item-spacer {
  @apply grid grid-cols-1 gap-10;
}
</style>
