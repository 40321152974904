<template>
  <main class="main">
    <!-- Content Slot -->
    <slot />
  </main>
</template>

<script>
export default {
  name: "AppMain",
};
</script>

<style scoped>
.main {
  @apply transition-all duration-100 ease-linear;
}
</style>
