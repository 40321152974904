<template>
  <div class="wrapper mt-10" v-if="options">
    <div class="text-white">
      <div class="title">{{ options.address.title }}</div>
      <div class="info">
        {{ options.address.street }}<br />
        {{ options.address.city }}
      </div>
    </div>
    <div class="text-white text-center">
      <div class="title">{{ options.email.title }}</div>
      <a :href="'mailto:' + options.email.email" class="info email">{{
        options.email.email
      }}</a>
    </div>
    <div class="text-white">
      <div class="title">{{ options.phone.title }}</div>
      <div class="info">
        <div v-if="options.phone.tel">
          Tel:
          <a
            class="phone"
            :href="'tel:' + options.phone.tel"
            v-html="options.phone.tel"
          ></a>
        </div>
        <div v-if="options.phone.fax">
          Fax: <span v-html="options.phone.fax"></span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">wird geladen...</div>
</template>

<script>
export default {
  name: "ContactModule",
  data() {
    return {
      options: null,
    };
  },
  mounted() {
    this.getValues();
  },
  methods: {
    getValues() {
      this.axios
        .post("/api/collections/get/options", {
          filter: {
            code: "contact_footer",
          },
        })
        .then((r) => {
          this.options = r.data.entries[0].options;
        });
    },
  },
};
</script>

<style scoped>
.wrapper {
  @apply grid grid-cols-1 lg:grid-cols-3 gap-6 text-gray-700;
}
.title {
  @apply text-center pb-2 mb-2 text-3xl uppercase font-bold;
}
.info {
  @apply text-center;
}
.email,
.phone {
  @apply hover:underline inline-block mx-auto;
}
</style>
