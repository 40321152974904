<template>
  <div>
    <div class="text-center mb-4">
      <FooterNavigationLink
        @click="showConsent"
        :link="{ name: 'Cookies anpassen' }"
      />
    </div>
    <div class="footer-navigation-wrapper">
      <!-- Cookies anpassen -->
      <!-- Weitere Links -->
      <FooterNavigationLink
        v-for="(link, index) in navigation"
        v-bind:key="'footer-nav-link-' + index"
        :link="link"
      />
    </div>
  </div>
</template>

<script>
import { footer } from "@/data/navigation";
import FooterNavigationLink from "@/components/Items/FooterNavigationLink";

export default {
  name: "FooterNavigation",
  components: { FooterNavigationLink },
  data() {
    return {
      navigation: footer,
    };
  },
  methods: {
    showConsent() {
      window.klaro.show();
    },
  },
};
</script>

<style scoped>
.footer-navigation-wrapper {
  @apply flex space-x-7 justify-center;
}
</style>
