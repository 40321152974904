<template>
  <FullPageImage
    ref="startMain"
    :src="Crowd"
    css="flex flex-col items-center text-center h-full justify-between"
  >
    <div class="text-white px-10 w-full">
      <div class="flex items-center justify-center">
        <img :src="Schriftzug" alt="Vitamin D" class="schriftzug" />
      </div>
    </div>
    <div class="mt-5">
      <OrangeButton text="AUF GEHTS" @click="letsstart" />
    </div>
  </FullPageImage>
</template>

<script>
import FullPageImage from "@/components/modules/FullPageImage";
import OrangeButton from "@/components/Buttons/OrangeButton";
import Schriftzug from "../assets/vitamin_d_schriftzug.png";
import Crowd from "../assets/crowd-1056764_1280.webp";

export default {
  name: "HomeStartSection",
  components: { OrangeButton, FullPageImage },
  data() {
    return {
      Schriftzug: Schriftzug,
      Crowd: Crowd,
    };
  },
  methods: {
    letsstart() {
      window.scroll({
        top: this.$refs.startMain.$el.clientHeight - 80,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.schriftzug {
  @apply w-10/12 sm:w-10/12 md:w-8/12 lg:w-6/12;
}
</style>
