<template>
  <div class="parallax-section">
    <div class="parallax-child-section">
      <section class="fw-main-row">
        <slot />
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullPageImage",
  props: {
    src: String,
    css: String,
  },
};
</script>

<style scoped>
.parallax-section {
  position: relative;
  width: 100%;
  height: 100vh;
}
.parallax-child-section {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.fw-main-row {
  position: fixed;
  display: block;
  @apply flex items-center justify-center flex-col;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  will-change: transform;
  z-index: 1;
  background-attachment: scroll;
  background-image: url("~@/assets/crowd-1056764_1280.webp");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
