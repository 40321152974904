<template>
  <div class="category-wrapper">
    <div class="title">{{ category.title }}</div>
    <div v-bind:class="{ 'graphics-wrapper': category.type === 'graphics' }">
      <DownloadItem
        v-for="(download, index) in category.downloads"
        v-bind:key="'download-item-' + category._id + index"
        :download="download"
      />
    </div>
  </div>
</template>

<script>
import DownloadItem from "@/components/Items/DownloadItem";
export default {
  name: "DownloadCategorieItem",
  components: { DownloadItem },
  props: {
    category: Object,
  },
};
</script>

<style scoped>
.title {
  @apply font-bold text-2xl uppercase mb-2;
}
.category-wrapper {
  @apply text-center;
}
.graphics-wrapper {
  @apply flex justify-center;
  flex-wrap: wrap;
}
</style>
