<template>
  <div class="social-wrapper">
    <SocialIcon
      v-for="(social, index) in social"
      v-bind:key="'social-icon-' + index"
      :link="social"
    />
  </div>
</template>

<script>
import SocialIcon from "@/components/Items/SocialIcon";
import { social } from "@/data/navigation";
export default {
  name: "SocialLinks",
  components: { SocialIcon },
  data() {
    return {
      social: social,
    };
  },
};
</script>

<style scoped>
.social-wrapper {
  @apply flex space-x-6 md:space-x-10 justify-center mb-7;
}
</style>
