<template>
  <section class="py-10 shadow-lg">
    <slot />
  </section>
</template>

<script>
export default {
  name: "BGWrapper",
};
</script>

<style scoped></style>
