<template>
  <SectionSpacer class="pt-20" :margin="true">
    <section>
      <PageContainer>
        <PageTitle as="h1" title="Downloads" />
        <DownloadsModule />
      </PageContainer>
    </section>
    <TextPlugin shortcode="download_info" />
  </SectionSpacer>
</template>

<script>
import DownloadsModule from "@/components/modules/DownloadsModule";
import PageTitle from "@/components/Title/PageTitle";
import PageContainer from "@/components/Wrapper/PageContainer";
import SectionSpacer from "@/components/Wrapper/SectionSpacer";
import { useMeta } from "vue-meta";
import TextPlugin from "@/components/Plugins/TextPlugin";
export default {
  name: "DownloadsView",
  components: {
    TextPlugin,
    SectionSpacer,
    PageContainer,
    PageTitle,
    DownloadsModule,
  },
  setup() {
    useMeta({
      title: "Downloads",
    });
  },
};
</script>

<style scoped></style>
