<template>
  <template v-if="downloads">
    <ItemSpacer>
      <DownloadCategorieItem
        v-for="(category, index) in downloads"
        v-bind:key="'download-category-' + index"
        :category="category"
      />
    </ItemSpacer>
  </template>
  <template v-else-if="!downloads && loading">
    <TextLoader />
  </template>
  <template v-else>
    <AlertInfo text="Derzeit stehen keine Downloads zur Verfügung." />
  </template>
</template>

<script>
import DownloadCategorieItem from "@/components/Items/DownloadCategorieItem";
import TextLoader from "@/components/Loader/TextLoader";
import ItemSpacer from "@/components/Wrapper/ItemSpacer";
import AlertInfo from "@/components/AlertInfo";
export default {
  name: "DownloadsModule",
  components: { AlertInfo, ItemSpacer, TextLoader, DownloadCategorieItem },
  data() {
    return {
      downloads: null,
      loading: true,
    };
  },
  mounted() {
    this.loadDownloads();
  },
  methods: {
    loadDownloads() {
      this.axios
        .post("/api/collections/get/download_categories", { populate: 2 })
        .then((r) => {
          this.downloads = r.data.entries;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
