<template>
  <div class="menu">
    <div class="close" @click="close"><img :src="icon" alt="schließen" /></div>
    <div class="menu-wrapper">
      <template
        v-for="(link, index) in navigation"
        v-bind:key="'menu-mobile-link' + index"
      >
        <router-link
          v-if="!link.only || link.only === $route.name"
          @click="close"
          class="link"
          :to="{ name: link.route }"
        >
          {{ link.name }}
        </router-link></template
      >
    </div>
  </div>
</template>

<script>
import CloseIcon from "../assets/images/icons/Close-108.png";
import { header } from "@/data/navigation";

export default {
  name: "MobileMenuOverlay",
  props: {
    close: Function,
  },
  data() {
    return {
      icon: CloseIcon,
      navigation: header,
    };
  },
};
</script>

<style scoped>
.menu {
  @apply bg-black bg-opacity-80 fixed z-50 left-0 right-0 top-0 bottom-0 p-6 overflow-auto md:hidden flex items-center justify-center backdrop-blur;
}
.menu-wrapper {
  @apply text-white space-y-10 text-3xl text-center;
}
.close {
  @apply absolute top-4 right-4 h-12 w-12 flex items-center justify-center rounded-full bg-black bg-opacity-95 hover:opacity-70 transition-all duration-100 ease-linear cursor-pointer;
}
.close img {
  @apply h-4 w-4;
}
.link {
  @apply block;
}
.router-link-exact-active {
  @apply rotate-3 font-bold text-amber-400 scale-125;
}
</style>
