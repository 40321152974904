<template>
  <div>
    <img
      :src="thumbnail"
      :alt="video.title"
      class="thumbnail"
      v-bind:class="{ playing: isActive }"
    />
  </div>
</template>

<script>
export default {
  name: "VideoItem",
  props: {
    video: {
      type: Object,
      required: true,
    },
    activeVideoId: String,
  },
  computed: {
    isActive() {
      return this.activeVideoId === this.video.youtube_video_id;
    },
    thumbnail() {
      return (
        "https://i3.ytimg.com/vi/" +
        this.video.youtube_video_id +
        "/hqdefault.jpg"
      );
    },
  },
};
</script>

<style scoped>
.thumbnail {
  @apply w-full cursor-pointer rounded-xl hover:shadow-lg transition-all duration-150 ease-linear border-4 border-transparent;
}
.playing {
  @apply border-white shadow-lg;
}
</style>
