<template>
  <template v-if="to">
    <router-link :to="to" class="button group">
      <span class="button-text">{{ text }}</span>
    </router-link>
  </template>
  <template v-else-if="href">
    <a :href="href" class="button group cursor-pointer">
      <span class="button-text px-4">{{ text }}</span>
    </a>
  </template>
  <template v-else>
    <div class="button group cursor-pointer">
      <span class="button-text px-4">{{ text }}</span>
    </div>
  </template>
</template>

<script>
export default {
  name: "OrangeButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
      required: false,
    },
    href: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.button {
  @apply bg-amber-400 select-none text-white font-bold inline-block px-6 py-3 rounded-xl shadow-lg hover:scale-110 transition-all duration-100 ease-linear;
}
.button-text {
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}
</style>
