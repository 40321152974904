<template>
  <div class="event-grid">
    <slot />
  </div>
</template>

<script>
export default {
  name: "EventGrid",
};
</script>

<style scoped>
.event-grid {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6;
}
</style>
