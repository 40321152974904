<template>
  <div class="page-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PageContainer",
};
</script>

<style scoped>
.page-container {
  @apply container mx-auto max-w-6xl px-6;
}
</style>
