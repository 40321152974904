<template>
  <BandLogo />
  <div class="hamburger-icon-container" @click="toggle" v-if="!showOverlay">
    <div class="hamburger-icon"></div>
  </div>
  <MobileMenuOverlay v-if="showOverlay" :close="toggle" />
</template>

<script>
import BurgerIcon from "../assets/images/icons/menu-icon.svg";
import BandLogo from "@/partials/BandLogo";
import MobileMenuOverlay from "@/partials/MobileMenuOverlay";
import { BodyScroll } from "@/mixins/BodyScroll";

export default {
  name: "MobileMenu",
  components: { MobileMenuOverlay, BandLogo },
  mixins: [BodyScroll],
  data() {
    return {
      burger: BurgerIcon,
      showOverlay: false,
    };
  },
  methods: {
    toggle() {
      this.showOverlay = !this.showOverlay;

      if (!this.showOverlay) {
        this.showScrollbar();
      } else {
        this.hideScrollbar();
      }
    },
  },
};
</script>

<style scoped>
.burger {
  @apply h-4 cursor-pointer;
}

.hamburger-icon-container {
  @apply cursor-pointer;
  height: 1.7em;
  width: 1.7em;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
}

.hamburger-icon,
.hamburger-icon:before,
.hamburger-icon:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 1.7em;
  background: white;
  transition: all 0.2s ease;
  @apply rounded-xl;
}

.hamburger-icon {
  top: 0.75em;
}

.hamburger-icon:before {
  top: -0.55em;
}

.hamburger-icon:after {
  top: 0.55em;
}
</style>
