<template>
  <section class="overflow-hidden text-gray-700" v-if="photos.length >= 6">
    <div class="x-auto" v-if="photos.length >= 6">
      <div class="flex flex-wrap -m-1 md:-m-2">
        <div class="flex flex-wrap lg:w-1/2">
          <div class="w-1/2 p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(0)"
            />
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(1)"
            />
          </div>
          <div class="w-full p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(2)"
            />
          </div>
        </div>
        <div class="flex flex-wrap lg:w-1/2">
          <div class="w-full p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(3)"
            />
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(4)"
            />
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(5)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto" v-if="photos.length >= 12">
      <div class="flex flex-wrap -m-1 md:-m-2" v-if="photos.length >= 12">
        <div class="flex flex-wrap lg:w-1/2">
          <div class="w-1/2 p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(6)"
            />
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(7)"
            />
          </div>
          <div class="w-full p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(8)"
            />
          </div>
        </div>
        <div class="flex flex-wrap lg:w-1/2">
          <div class="w-full p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(9)"
            />
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(10)"
            />
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <img
              alt="gallery"
              class="block object-cover object-center w-full h-full rounded-lg"
              :src="getPhoto(11)"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/data/api";

export default {
  name: "InstagramPhotos",
  data() {
    return {
      photos: [],
      limit: 30,
    };
  },
  mounted() {
    this.loadPhotos();
  },
  methods: {
    getPhoto(index) {
      return api.thumbs + this.photos[index].asset.path;
    },
    loadPhotos() {
      this.axios
        .post("/api/collections/get/photos", {
          populate: 2,
          limit: this.limit,
          sort: { pos: 1 },
        })
        .then((r) => {
          this.photos = r.data.entries.sort(function (a, b) {
            return parseInt(a.sort) - parseInt(b.sort);
          });
        });
    },
  },
};
</script>

<style scoped></style>
