<template>
  <section>
    <PageTitle as="h2" title="Fotos" />
    <PageContainer>
      <InstagramPhotos />
    </PageContainer>
  </section>
</template>

<script>
import PageTitle from "@/components/Title/PageTitle";
import PageContainer from "@/components/Wrapper/PageContainer";
import InstagramPhotos from "@/components/modules/InstagramPhotos";
export default {
  name: "PhotoSection",
  components: { InstagramPhotos, PageContainer, PageTitle },
};
</script>

<style scoped></style>
