<template>
  <template v-if="link.route">
    <router-link class="link" :to="{ name: link.route }">{{
      link.name
    }}</router-link>
  </template>
  <template v-else>
    <div class="link">
      {{ link.name }}
    </div>
  </template>
</template>

<script>
export default {
  name: "FooterNavigationLink",
  props: {
    link: Object,
  },
};
</script>

<style scoped>
.link {
  @apply text-sm hover:underline cursor-pointer;
}
.router-link-exact-active {
  @apply font-bold text-black;
}
</style>
