<template>
  <div class="video-playlist-wrapper">
    <template
      v-for="(video, index) in videos"
      v-bind:key="'youtube-video-' + index"
    >
      <VideoItem
        :video="video"
        :active-video-id="active"
        @click="setActiveVideo(video.youtube_video_id)"
      />
    </template>
  </div>
</template>

<script>
import VideoItem from "@/components/Items/VideoItem";
export default {
  name: "VideoPlaylist",
  components: { VideoItem },
  props: {
    active: String,
    videos: Object,
    setActiveVideo: Function,
  },
};
</script>

<style scoped>
.video-playlist-wrapper {
  @apply grid grid-cols-2 md:grid-cols-4 gap-4 mt-3;
}
</style>
