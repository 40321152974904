<template>
  <SectionSpacer class="pt-20" :margin="true">
    <section>
      <PageContainer>
        <PageTitle as="h1" title="Datenschutz" />
        <TextPlugin shortcode="datenschutz" />
      </PageContainer>
    </section>
  </SectionSpacer>
</template>

<script>
import PageContainer from "@/components/Wrapper/PageContainer";
import PageTitle from "@/components/Title/PageTitle";
import SectionSpacer from "@/components/Wrapper/SectionSpacer";
import TextPlugin from "@/components/Plugins/TextPlugin";
import { useMeta } from "vue-meta";
export default {
  name: "PrivacyView",
  components: { TextPlugin, SectionSpacer, PageTitle, PageContainer },
  setup() {
    useMeta({
      title: "Datenschutz",
    });
  },
};
</script>

<style scoped></style>
