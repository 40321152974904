<template>
  <SectionSpacer class="pt-20" :margin="true">
    <!-- Kontakt -->
    <section>
      <PageContainer>
        <PageTitle as="h1" title="Impressum" />
        <TextPlugin shortcode="kontakt" />
      </PageContainer>
    </section>
    <ContactSection />
    <!-- Lizenzen -->
    <section>
      <PageContainer>
        <PageTitle as="h1" title="Lizenzen" />
        <TextPlugin shortcode="license" />
      </PageContainer>
    </section>
    <!-- Impressum -->
    <section>
      <PageContainer>
        <TextPlugin shortcode="impressum" />
      </PageContainer>
    </section>
  </SectionSpacer>
</template>

<script>
import PageContainer from "@/components/Wrapper/PageContainer";
import PageTitle from "@/components/Title/PageTitle";
import SectionSpacer from "@/components/Wrapper/SectionSpacer";
import TextPlugin from "@/components/Plugins/TextPlugin";
import { useMeta } from "vue-meta";
import ContactSection from "@/sections/ContactSection";
export default {
  name: "ContactView",
  components: {
    ContactSection,
    TextPlugin,
    SectionSpacer,
    PageTitle,
    PageContainer,
  },
  setup() {
    useMeta({
      title: "Kontakt & Impressum",
    });
  },
};
</script>

<style scoped></style>
