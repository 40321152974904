<template>
  <footer class="footer">
    <PageContainer class="footer-inner">
      <!-- Social Links -->
      <SocialLinks />
      <!-- Navigation -->
      <FooterNavigation />
    </PageContainer>
    <!-- Copyright -->
    <AppCopyright />
  </footer>
</template>

<script>
import PageContainer from "@/components/Wrapper/PageContainer";
import SocialLinks from "@/components/modules/SocialLinks";
import FooterNavigation from "@/partials/FooterNavigation";
import AppCopyright from "@/partials/AppCopyright";
export default {
  name: "AppFooter",
  components: { AppCopyright, FooterNavigation, SocialLinks, PageContainer },
};
</script>

<style scoped>
.footer-inner {
  @apply space-y-2;
}
.footer {
  @apply mt-14;
}
</style>
