<template>
  <div class="section-spacer" v-bind:class="{ 'mt-10': margin }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SectionSpacer",
  props: {
    margin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.section-spacer {
  @apply grid grid-cols-1 gap-20;
}
</style>
